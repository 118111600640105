/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

// jQuery
import $ from 'jquery';
import Headroom from 'headroom.js';

$(async function () {

	// Responsive menu
	$('header').each(function () {
		var element = $(this),
			body = $(document.body),
			responsiveNav = $('.responsive-nav');

		var toggler = $('<div class="toggler toggler--closed"><span>Menu</span></div>').click(function () {
			body.toggleClass('open');
			responsiveNav.toggleClass('collapsed');
			toggler.toggleClass('toggler--closed');
		}).appendTo(element);
	});

	var headroom = new Headroom($('header')[0]);
	headroom.init();

	// Slideshow
	$('.slideshow--frontpage').each(async function () {
		await import('lightslider');

		var element = $(this);
		var cssClass = element.attr('class');
		if (element.children('figure').length > 1) {
			element.lightSlider({
				item: 1,
				slideMargin: 0,
				addClass: cssClass,
				cssEasing: 'linear',
				mode: 'fade',
				auto: true,
				loop: true,
				pause: 6000,
				speed: 1000,
				controls: false,
				pager: true,
				onBeforeSlide: function (el, i) {
					// TODO: Move CSS to stylesheet and use classes
					el.children('.lslide').eq(i).find('figcaption h2').css({
						'margin-top': '10px',
						'opacity': '0',
						'transition': 'none'
					});
					el.children('.lslide').eq(i).find('figcaption .button').css({
						'opacity': '0',
						'transition': 'none'
					});
				},
				onAfterSlide: function (el, i) {
					el.children('.lslide').eq(i).find('figcaption h2').css({
						'margin-top': '0',
						'opacity': '1',
						'transition': 'transform 1s ease 0s, opacity 1s ease 0s, margin 1s ease 0s, background 1s ease 0s'
					});
					el.children('.lslide').eq(i).find('figcaption .button').css({
						'opacity': '1',
						'transition': 'transform 1s ease 1s, opacity 1s ease 1s, background 1s ease 1s'
					});
				}
			});
		}
	});

	// Slider
	$('.slider').each(async function () {
		await import('lightslider');
		var element = $(this);
		var cssClass = element.attr('class');

		element.lightSlider({
			slideMargin: 0,
			loop: true,
			auto: true,
			speed: 1000,
			pause: 3500,
			pager: false,
			controls: false,
			item: 7,
			responsive: [
				{
					breakpoint: 1920,
					settings: {
						item: 6
					}
				},
				{
					breakpoint: 1680,
					settings: {
						item: 5
					}
				},
				{
					breakpoint: 1440,
					settings: {
						item: 4
					}
				},
				{
					breakpoint: 1024,
					settings: {
						item: 3
					}
				},
				{
					breakpoint: 640,
					settings: {
						item: 2
					}
				},
				{
					breakpoint: 480,
					settings: {
						item: 1
					}
				}
			]
		});
	});

	// Lightbox
	$('#container:has([rel=lightbox])').each(async function () {
		await import('lightgallery');

		$(this).lightGallery({
			selector: '[rel=lightbox]',
			download: false,
			thumbnail: false,
			fullScreen: false,
			actualSize: false,
			hash: false
		});
	});

	// Products content
	$('.product--collapsible').each(function () {
		var element = $(this),
			content = element.find('.content');

		$(window).on("load resize", function (e) {
			// Set top position based on element
			var pos = element.position();
			content.css("top", pos.top + element.height());
		});

		element.click(function (event) {
			if (event.which === 1) {
				// Remove the active class from all elements with active class
				if (element.hasClass('active')) {
					element.removeClass('active');
					element.parent().removeClass('active');
				}
				else {
					$(".product").removeClass("active");
					element.addClass("active");
					element.parent().addClass("active");

					$('html, body').animate({
						scrollTop: element.offset().top
					}, 500);
				}
				event.stopPropagation();
			}
		});

		//var close = $('<div class="close"><span>Sluiten</span></div>').click(function () {
		//    element.removeClass('active');
		//}).appendTo(content);
	});

	if (window.location.hash) {
		var hash = window.location.hash.substring(1);

		$('.product--collapsible figure').each(function () {
			var id = $(this).attr('id');
			console.log(id);
			if (hash === id) {
				$(this).closest('div').addClass('active');
				$('.products').addClass('active');
			}
		});
	}

	// Route form
	$('.form--route').each(function () {
		var element = $(this);
		var button = element.find('button');
		var destinationAddress = element.find('input[type=text]');
		var destination = button.attr('data-address');

		// Route on button click
		button.click(function (e) {
			var route = {
				saddr: destinationAddress.val(),
				daddr: destination.val()
			};

			window.open('https://maps.google.com/?' + $.param(route), '_blank');

			e.preventDefault();
		});
	});

	// Smooth scroll
	$('a[href*=\\#]').click(function () {
		var target = $(this.hash);

		if (!target.length) {
			target = $('[name=' + this.hash.slice(1) + ']');
		}

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 500);

			return false;
		}
	});
});

//$(document).ready(function () {

//    // Open language on touch
//    $('.language').each(function () {
//        var element = $(this)

//        element.on("touchend", function (e) {
//            element.toggleClass('language--open');
//        });
//    });

//});